.contact-header {
    margin-bottom: 20px;
}

.contact-header h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

.contact-content {
    font-size: 1em;
    margin-bottom: 20px;
}

.contact-content h2 {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 10px;
}

.contact-content p {
    margin: 15px 0;
    line-height: 1;
}

.cta-button {
    display: inline-block;
    background: #000;
    color: #fff;
    border: none;
    border-radius: 25px;
    font-size: 22px;
    cursor: pointer;
    width: 98%;
    margin: 20px 0;
}

.cta-button:hover {
    background-color: #555;
}