.about-content p {
    font-size: 1em;
    margin-bottom: 15px;
}

.about-content p strong {
    font-weight: bold;
    color: #333;
    font-size: 16px;
}

.cta-button {
    display: inline-block;
    margin-top: 20px;
    padding: 5px 30px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 25px;
    font-size: 22px;
    cursor: pointer;
    width: 98%;
    font-family: acumin-variable;
    font-weight: bold;
}

.cta-button:hover {
    background-color: #555;
}