.portfolio-header {
    display: flex;
    justify-content: center;
}

.portfolio-header h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

.portfolio-content p {
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 20px;
}

.portfolio-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.portfolio-item {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    text-align: left;
}

.portfolio-item h2 {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 10px;
}

.portfolio-item p {
    font-size: 1em;
    line-height: 1.5;
}

.back-home {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    color: #fff;
    background-color: #333;
    text-decoration: none;
    font-size: 1em;
}

.back-home:hover {
    background-color: #555;
}

.carousel .slide {
    background: #f9f9f9;
}
  
.carousel .control-arrow {
    background-color: rgba(0, 0, 0, 0.5);
}