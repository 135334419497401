body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'acumin-variable', sans-serif;
    font-size: 16px;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* body, p, span,button {
  font-weight: 100 !important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Apply font weight 300 to specific elements including buttons */
 button {
  font-variation-settings: "slnt" 0, "wdth" 100, "wght" 100; /* Thin weight */
}
