.navigation {
    position: relative;
    z-index: 1000;
}

.menu {
    width: 20%;
    margin: 0 auto;
    margin-top: 20px;
}

.menu a {
    display: flex;
    color: #000;
    border: 2px solid #000;
    border-radius: 25px;
    font-size: 20px;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    padding-bottom: 4px !important;
    margin-bottom: 20px;
    text-decoration: none;
}

.menu a:hover {
    background-color: #f0f0f0;
}

.menu-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #000;
    cursor: pointer;
    padding: 5px;
    position: fixed;
    top: 20px;
    right: -160px;
    z-index: 1001;
}

.menu-popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 60px;
    z-index: 9999;
}

@media (max-width: 768px) {
    .menu {
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;
    }
}