.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 200px;
    z-index: 1000;
}

.modal-content {
    background-color: rgba(255, 255, 255, 0.94);
    width: 80%;
    max-width: 350px;
    max-height: 90vh;
    padding: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 15px;
    border: 3px solid #000;
    position: relative;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(0);
}

.close-button {
    position: absolute;
    top: -10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 36px;
    color: #000;
    cursor: pointer;
    padding: 5px;
    padding-top: 15px;
    width: auto;
    font-weight: bolder;
    font-family: math;
}

.modal-text {
    text-align: left;
    font-family: 'acumin-variable';
    font-weight: 100 !important;
    line-height: 0.6;
}

.modal-text h3 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 600;
    line-height: 0.6;
}

.modal-text p {
    font-size: 16px;
    line-height: 0.6;
    margin: 5px 0;
    font-weight: 100 !important;
}

.office-hours {
    margin: 20px 0;
    padding: 10px 0;
}

.contact-info {
    margin: 20px 0;
}

.management-section {
    margin-top: 25px;
}

.management-section h3 {
    color: #000;
    margin-bottom: 15px;
}

.management-button {
    background: #000;
    color: #fff;
    border: none;
    border-radius: 25px;
    padding: 6px 30px;
    font-size: 22ppx;
    margin-top: 15px;
    cursor: pointer;
    width: 100%;
    display: inline-block;
    min-width: 150px;
}

.management-button:hover {
    opacity: 0.9;
}

@media (max-width: 480px) {
    .modal-content {
        width: 90%;
        padding: 50px 15px;
    }

    .close-button {
        top: -10px;
        right: 10px;
        font-size: 40px;
    }

    .modal-text p {
        font-size: 16px;
    }

    .management-button {
        padding: 10px 25px;
        font-size: 22px;
    }

    .modal-overlay {
        top: -60px;
    }
}