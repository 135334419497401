.management-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.management-header h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

.management-content p {
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 20px;
}

.download-button {
    padding: 12px;
    background: #000;
    color: #fff;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    width: 98%;
}

.download-button:hover {
    background-color: #555;
}

.back-home {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    color: #fff;
    background-color: #333;
    text-decoration: none;
    font-size: 1em;
}

.back-home:hover {
    background-color: #555;
}

.online-training {
    margin-top: 30px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.online-training h2 {
    margin-bottom: 15px;
}

.training-link {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #000000;
    color: white;
    text-decoration: none;
    border-radius: 25px;
}

.training-link:hover {
    background-color: #555;
}