.prices-header h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 10px;
}

.prices-content p {
    font-size: 1em;
    margin-bottom: 15px;
    text-align: left;

}

.prices-content ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
}

.prices-content li {
    font-size: 1.1em;
    color: #000;
    margin: 5px 0;
}