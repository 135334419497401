* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Acumin Variable Concept', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    color: black;
}

.app {
    min-height: 100vh;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.logo img {
    width: 250px;
    margin-bottom: 30px;
}

.main {
    width: 375px; 
    margin: 0 auto;
    padding: 20px;
    position: relative;
    padding-top: 80px; 
}