.menu-icon {
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
}

form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
}

input {
    padding: 6px;
    border: 2px solid #000;
    border-radius: 25px;
    font-size: 18px;
    width: 98%;
    box-sizing: border-box;
    font-Weight: 300 
}

button {
    padding: 6px;
    background: #000;
    color: #fff;
    border: none;
    border-radius: 25px;
    font-size: 22px;
    cursor: pointer;
    width: 98%;
    font-family: acumin-variable;
    font-weight: bold;
}

button:disabled {
    background: #666;
    cursor: not-allowed;
}

.info {
    margin-top: 30px;
    text-align: left;
}

.highlight {
    font-weight: bold;
}

.pricing {
    margin-top: 10px;
    padding-top: 20px;
    text-align: center;
}

.licence {
    font-size: 10px; 
    text-align: center; 
}

.input-container {
    position: relative;
}
  
.input-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
}
  
.input-container input {
    padding-left: 40px; 
}
  
.tooltip {
    position: absolute;
    bottom: 1px;
    right: 22px;
    color: #ed1671;
    font-size: 22px;
    font-weight: bold;
}

.form-error {
    color: #ed1671;
    font-size: 1rem;
    margin-bottom: 10px;
    text-align: center;
}